import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { Carousel, Container, Row, Col, ListGroup, Card, Button } from 'react-bootstrap';

import landingPageImage from './images/LandingPage.png';
import openRequestsImage from './images/OpenRequests.png'
import editRequestImage from './images/EditRequest.png'

import TermsOfUse from './TermsOfUse'; // Import the TermsOfUse component
import PrivacyPolicy from './PrivacyPolicy';
import FAQ from './FAQ'; // Import the FAQ component
import BetaSignup from './BetaSignup'; // Import the BetaSignup component


function App() {
  console.log("Inside App.js")
  return (
    // Log to check function call
    < Router >
      <div className="App">
        {/* Header Section */}
        <header className="bg-primary bg-gradient text-warning text-center py-4">
          <h2>This is work in progress, stay tuned for the official launch.</h2>
        </header>
        <header className="bg-primary bg-gradient text-white text-center py-4" style={{ marginBottom: '40px' }}>
          <h1>Simple car sharing for family members</h1>
          <p>Parents (owners) manage hausehold cars occasional usage with users, their young adults.</p>
        </header>
        {/* Main Content */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                {/* Carousel Section */}
                <Container className="carousel-wrapper" >
                  <Carousel >
                    {/* Use the same image three times */}
                    <Carousel.Item>
                      <img
                        className="carousel-image mx-auto d-block"
                        src={landingPageImage}
                        alt="Slide 1"
                      />
                      <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="carousel-image mx-auto d-block"
                        src={openRequestsImage}
                        alt="Slide 2"
                      />
                      <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="carousel-image mx-auto d-block"
                        src={editRequestImage}
                        alt="Slide 3"
                      />
                      <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </Container>


                {/* Features Section */}
                <Container className="my-4">
                  <h2 className="text-center">Features</h2>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Simple approval for car usage request, with push notifications.</ListGroup.Item>
                    <ListGroup.Item>Up-to-date overview of open, approved and past requests.</ListGroup.Item>
                    <ListGroup.Item>After completion, user reports back to owner.</ListGroup.Item>
                    <ListGroup.Item>Car owners manage who can send request for their car.</ListGroup.Item>
                    <ListGroup.Item>Users can send requests to different car owners.</ListGroup.Item>
                    <ListGroup.Item>Before creating a request, user can check other requests for the selected car.</ListGroup.Item>
                    <ListGroup.Item>Car owner can add their requests when approving, e.g. visit fuel station.</ListGroup.Item>
                    <ListGroup.Item>Implemented Forget me for permanent deletion of data.</ListGroup.Item>
                    <ListGroup.Item>Available in Slovenian and English, other languages are coming.  </ListGroup.Item>
                    <ListGroup.Item>Currently tested on Android, iPhone is coming soon...</ListGroup.Item>
                    {/*   <ListGroup.Item>Implemented "Forget me" button.</ListGroup.Item> */}
                  </ListGroup>
                </Container>

                {/* Beta Signup Section */}
                <BetaSignup />

                {/* Pricing Section */}
                <Container className="my-4" >
                  <h2 className="text-center">Pricing</h2>
                  <Row className="text-center">
                    <Col md={6}>
                      <Card className="mb-4 shadow-sm">
                        <Card.Header>Free</Card.Header>
                        <Card.Body>
                          <Card.Title>0 EUR</Card.Title>
                          {/*      <Card.Title> {'\u00A0'} </Card.Title> */}
                          <Card.Text>
                            All features available,
                            <p>limited to total 20 requests to use car(s). </p>

                          </Card.Text>
                          <Button variant="primary">Sign Up for Free</Button>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className="mb-4 shadow-sm" >
                        <Card.Header>Paid (for users, owners do not pay)</Card.Header>
                        <Card.Body>
                          <Card.Title>10 EUR (+VAT) / year / car OR </Card.Title>
                          <Card.Title>10 cents EUR / created request </Card.Title>

                          <Card.Text>All features available at the subscription date.</Card.Text>

                          <Button variant="success">Subscribe Now</Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                {/* FAQ Section */}
                <FAQ />
              </>
            }
          />
          <Route path="/legal" element={<TermsOfUse />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>

        {/* Footer Section */}
        <footer className="bg-primary bg-gradient text-white text-center py-3">
          <Container>
            <p>HomeCarSync is owned by Salvirt ltd., Slovenia</p>
            <p>
              <Link to="/" className="text-white">Home</Link> | <Link to="/legal" className="text-white">Legal</Link> |  <Link to="/privacy" className="text-white">Privacy policy</Link> | <a href="mailto:homecarsync_support@salvirt.com" className="text-white">Contact Us</a>
            </p>
          </Container>
        </footer>
      </div>
    </Router >
  );
}

export default App;
