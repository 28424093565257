import React, { useState } from 'react';
import { Container, Button, Collapse, Card } from 'react-bootstrap';

function FAQ() {
  // State to manage whether the entire FAQ section is visible
  const [faqVisible, setFaqVisible] = useState(false);
  // State to manage which question is expanded
  const [open, setOpen] = useState(null);

  // List of FAQ questions and answers
  const faqs = [
    { question: "How do I sign up?", answer: "To sign up, click on the 'Sign Up' button on the home page and fill in your email and password. You will get PIN code to confirm email." },
    { question: "Is the app free to use?", answer: "Yes, the app is free to use with a limit of 20 requests. Additional requests are available with a subscription." },
    { question: "Who pays for application use?", answer: "A user (who create requests to use cars) pays for creating requests for a car. Owners do not pay for the usage of the app." },
    { question: "How do I reset my password?", answer: "Go to the login page and click on 'Forgot Password' to reset your password." },
    { question: "Can I share my car with multiple users?", answer: "Yes, you can share your car with multiple family members by adding them through the app." },
    { question: "Is my data secure?", answer: "We use encryption and follow best practices to ensure your data is secure." },
    { question: "How do I delete all my data?", answer: "Within Settings/User profile click on 'Forgot me' to permanentnly delete all your data. If you register again later, you will start from zero." },
  ];

  // Handler to toggle the selected question
  const toggleFAQ = (index) => {
    setOpen(open === index ? null : index);
  };

  return (
    <Container className="my-4">
      <Button
        onClick={() => setFaqVisible(!faqVisible)}
        style={{
          backgroundColor: '#007bff', // Blue background
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow
          border: 'none',
          width: '30%',
          margin: '0 auto',
          display: 'block',
          marginBottom: '20px',
        }}
      >
        FAQ
      </Button>
      <Collapse in={faqVisible}>
        <div>
          {faqs.map((faq, index) => (
            <Card key={index} className="my-2">
              <Card.Header>
                <Button
                  variant="link"
                  onClick={() => toggleFAQ(index)}
                  aria-controls={`faq-collapse-${index}`}
                  aria-expanded={open === index}
                  className="w-100 text-left"
                >
                  {faq.question}
                </Button>
              </Card.Header>
              <Collapse in={open === index}>
                <Card.Body id={`faq-collapse-${index}`}>
                  {faq.answer}
                </Card.Body>
              </Collapse>
            </Card>
          ))}
        </div>
      </Collapse>
    </Container>
  );
}

export default FAQ;