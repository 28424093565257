import React from 'react';
import { Container } from 'react-bootstrap';

function TermsOfUse() {
  return (
    <Container className="my-4">
      <h2>Terms and Conditions of Use</h2>

      <h4>1. Introduction</h4>
      <p>Welcome to HomeCarSync, a mobile app created bt Salvirt ltd. from Slovenia. By using our service, you agree to comply with these erms and Conditions of Use.</p>

      <h4>2. User Obligations</h4>
      <p>Users must be at least 16 years old and have valid state issued permision document to drive car. Users must use the app for its intended purpose (car sharing between family members).</p>

      <h4>3. Account Creation</h4>
      <p>Users must provide accurate information during registration. Only one account per individual is allowed.</p>

      <h4>4. Privacy and Data Collection</h4>
      <p>We collect user data as outlined in our Privacy Policy, including location and usage data for app functionality.</p>

      <h4>5. Permissions</h4>
      <p>Users grant us permission to access necessary device features (e.g., location, storage) to enhance the app's functionality.</p>

      <h4>6. Content Sharing</h4>
      <p>Users are responsible for the content they share within the app (e.g., requests, approvals). Any misuse or inappropriate content is prohibited.</p>

      <h4>7. Your Feedback</h4>
      <p>By submitting feedback, suggestions, or ideas through this app, you grant Salvirt ltd an irrevocable, royalty-free, and perpetual license to use, modify, and implement such feedback for any purpose without any obligation to provide compensation, acknowledgment, or attribution.</p>


      <h4>8. Family Sharing</h4>
      <p>Users can share access to the car through the app. Misuse of shared privileges may lead to account suspension.</p>

      <h4>9. Limitations of Liability</h4>
      <p>The creator of HomeCar Sync is not liable for any misuse of the car or damage that occurs during the usage period.</p>

      <h4>10. Termination</h4>
      <p>We reserve the right to suspend or terminate user accounts in cases of misuse or breach of these terms.</p>

      <h4>11. Modifications to Terms</h4>
      <p>We may update these Terms of Use periodically. Continued use of the app implies acceptance of any changes.</p>

      <h4>12. Contact Information</h4>
      <p>For questions or concerns, contact us at support@salvirt.com.</p>


      <p>Ljubljana, 8. October 2024</p>
    </Container>
  );
}

export default TermsOfUse;
