import React from 'react';
import { Container } from 'react-bootstrap';

function PrivacyPolicy() {
  return (
    <Container className="my-4">
      <h2>Privacy Policy</h2>

      <h4>1. Introduction</h4>
      <p>We are committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your information.</p>

      <h4>2. Information We Collect</h4>
      <p>We collect personal information such as email, which is needed for use the app. The data is stored by the provider in Slovenia, Europe. </p>

      <h4>3. How We Use Information</h4>
      <p>Your information is used to provide, improve, and maintain the services we offer, including car sharing and notifications. We can use your email to send you updates and notificiations related to the app.</p>

      <h4>4. Sharing of Information</h4>
      <p>We do not share your personal information with third parties, except as required by law or to protect our services.</p>

      <h4>5. Data Security</h4>
      <p>We implement security measures to protect your data. However, no method of transmission over the internet is 100% secure.</p>

      <h4>6. User Rights</h4>
      <p>You have the right to access, modify, or delete your personal data. Those functionalities are accessible within app after login. Contact us at [homecarsync_support@salvirt.com] to exercise these rights.</p>

      <h4>7. Changes to This Policy</h4>
      <p>We may update this policy periodically. Continued use of the app implies acceptance of any changes.</p>

      <h4>8. Contact Information</h4>
      <p>For questions or concerns regarding this policy, contact us at[homecarsync_support@salvirt.com].</p>
    </Container>
  );
}

export default PrivacyPolicy;
