import React, { useState } from 'react';
import { Container, Form, Button, Alert, Modal } from 'react-bootstrap';

function BetaSignup() {
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to control button

  // Function to validate email format
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    console.log("Inside handleSubmit"); // Log to check function call
    e.preventDefault();
    setError('');
    setIsSubmitting(true); // Set button to idle state
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      setIsSubmitting(false); // Allow submission if validation fails
      return;
    }

    // Prepare the data to be sent
    const data = {
      to: 'support@salvirt.com',
      subject: 'Beta user',
      body: `User Email: ${email}`,
    };

    try {
      // console.log("Before fetch call"); // Log to verify before request

      const response = await fetch('https://api.salvirt.com/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'KajSiTiNor1234321KajSeTeTotiGrejo!!!!####'
        },
        body: JSON.stringify(data), // Correctly stringify the data
      });

      // console.log("After fetch call"); // Log to verify after request

      if (response.ok) {
        setShowModal(true);
        setEmail(''); // Clear the email input
      } else {
        setError('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.log("Error occurred: ", error); // Log the error
      setError('An error occurred. Please try again later.');
    }
    setIsSubmitting(false);
  };


  return (
    <Container className="my-4">
      <h2 className="text-center">How Do I Become a User of the Public Beta Version?</h2>
      <Form onSubmit={handleSubmit}  >
        <Form.Group controlId="formEmail">
          <Form.Label>Why? <p> If you are car owner, you can invite up to 3 users, and each gets first 30 requests for free. <p></p> If you plan to use other cars, you can invite up to 3 car owners, and with each you have first 30 requests for free.</p>
            <p>Enter your email below to join our public beta and we will send you download location and instructions (Android only).</p> </Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        {/*  <Button variant="primary" type="submit" className="mt-3"  >
          Submit
        </Button> */}
      </Form>

      {/* Modal for success message */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be contacted shortly.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default BetaSignup;
